// src/components/Testimonials.tsx
import React from "react";

interface Testimonial {
  name: string;
  role: string;
  testimonial: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Emily Roberts",
    role: "Head of Business Development, Absolute IT Asset Disposals",
    testimonial:
      "Fast, efficient and talented!  Exine have developed a bespoke portal to suit our organisational needs in record time by meticulously listening to our requirements and communicating effectively to produce the perfect outcome.  We cannot thank you enough!",
  },
  {
    name: "Gavin Griffiths",
    role: "MD, Insurgo Media Services",
    testimonial:
      "I have worked with Exine for over 5 years now and their ability to innovate and deliver has been fundamental in supporting Insurgo's growth.  They have been incredibly flexible and responsive as Insurgo's fast growth has meant that requirements can often change.",
  },
  // {
  //   name: "Rachel Hall",
  //   role: "COO, S2S Group",
  //   testimonial:
  //     " ",
  // },
    {
    name: "Joel Earps",
    role: "Founder, NLP Ltd",
    testimonial:
      "Working with Exine was a fantastic experience. As an early-stage startup, they listened closely to my specifications and fully understood the project before moving forward. Their attention to detail and willingness to take feedback ensured the embedded product they delivered was of exceptional quality. Thanks to their technical expertise and professionalism, I was able to confidently prove my concept. Rich and Aaron were communicative, proactive, and collaborative, making the process smooth and efficient. I highly recommend Exine for delivering top-notch embedded solutions",
  },
];

const Testimonials: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-primary-dark">
          What Our Clients Say
        </h2>
        <div className="flex flex-wrap justify-center">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="max-w-sm bg-white rounded-lg shadow-lg overflow-hidden mx-4 mb-6"
            >
              <div className="p-6">
                <h3 className="text-xl font-bold text-primary">
                  {testimonial.name}
                </h3>
                <p className="text-primary-dark">{testimonial.role}</p>
                <p className="mt-4 text-gray-700">{testimonial.testimonial}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
